import { css } from "goober";

export const TableContainer = () => css`
  .items-meta {
    display: flex;
    gap: var(--sp-1);
  }
  .item-meta {
    display: flex;
    align-items: center;
    gap: var(--sp-2);
    padding: var(--sp-1);
    padding-inline-end: var(--sp-2);
    background: color-mix(in hsl, var(--wep-category-color), transparent 90%);
    color: var(--wep-category-color);
    border-radius: var(--br);
    box-shadow: inset 0 0 0 1px
      color-mix(in hsl, var(--wep-category-color), transparent 85%);
  }
  .item-img {
    width: var(--sp-7);
  }
  .item-name {
    width: 7em;
    white-space: normal;
    line-height: 1.25;
  }
`;
